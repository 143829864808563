import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {store} from './store'
import FloatingVue from 'floating-vue'
import {createPinia} from 'pinia'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/v4-shims.min.css'
import 'floating-vue/dist/style.css'
import '@/assets/main.css'

const pinia = createPinia();

createApp(App)
	.use(store)
	.use(pinia)
	.use(router)
	.use(FloatingVue)
	.mount('#app')
